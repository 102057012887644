import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box, Text } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';

const PurchaseSuccess = ({ data }) => (
  <Layout>
    <Head pageTitle={data.purchaseJson.title} />
    <Box fill="horizontal">
      <Box width="xlarge" alignSelf="center">
        <SectionTitle title="Thank you for your purchase!" />
        <Text textAlign="center">
          We have recieved your order and are processing your request. We hope
          our products help you attain better training outcomes!
        </Text>
      </Box>
    </Box>
  </Layout>
);

PurchaseSuccess.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PurchaseSuccess;

export const query = graphql`
  query PurchaseSuccessQuery {
    purchaseJson {
      title
    }
  }
`;
